/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.main-slider').slick({
          dots: true,
          infinite: true,
          speed: 500,
          fade: true,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: 'linear',
          prevArrow: $('.main-slider-prev'),
          nextArrow: $('.main-slider-next'),
        });

        $('.nav-button-click').click(function(){
          $(".mobile-nav").addClass('active');
          $(".mobile-menu").delay(500).fadeIn();
        });

        $('.close-nav').click(function(){
          $(".mobile-menu").fadeOut().delay(500).queue(function(next){
              $(".mobile-nav").removeClass('active');
              next();
          });
        });

        $('.search-icon').click(function(){
          $(".search-bar").toggleClass('active');
        });
        var theInput = document.getElementById('custom___2f3a96f2a6d79fd8bb0019b143ba3c80_0');
        if (theInput) {
          theInput.value = document.getElementById('product-title-name').innerHTML;
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // check cookie when video form is submitted dot mailer
       var visited = $.cookie("video");

        if (visited == null) {
          $('.cookie-not-present').show();
          $('.cookie-present').hide(); 
          $('.video-alert').hide(); 
          $('.video-overlay-form').show();
          $('.form-filled-in').hide();  
        } else {
          $('.cookie-not-present').hide();
          $('.cookie-present').show();
          $('.video-alert').show(); 
          $('.video-overlay-form').hide();
          $('.form-filled-in').show(); 
        }

        $('.watch-video, .video-content .ctct-submitted').click(function(){
              $.cookie('video', 'yes', { expires: 30, path: '/' });  
        });

        // for constant contact when the first name input is pressed it fills the custom field with the title of the product to capture the data
        var $tagsInput = $('.form-area .custom___d32ae7df7cfb4807adb37fee5e0832c2');
        $('.form-area .first_name___96e00d904f823909227407ea748d8d72').one("click", function() {
            $tagsInput[0].value += $('.hidden-title').text();
            return false;
        });

        // check cookie for whitepapers
        var whitePaper = $.cookie("whitePaper");

        if (whitePaper == null) {
         $('.white-paper-cookie-not-present').show();
         $('.white-paper-cookie-present').hide();        
        } else {
          $('.white-paper-cookie-not-present').hide();
         $('.white-paper-cookie-present').show();   
        }

      $('.download-paper, .papers-content .ctct-submitted').click(function(){
            $.cookie('whitePaper', 'yes', { expires: 30, path: '/' });        
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
